/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.AOS = require('aos/dist/aos');
window.Typed = require('typed.js');
//
// window.Vue = require('vue').default;
//
// /**
//  * The following block of code may be used to automatically register your
//  * Vue components. It will recursively scan this directory for the Vue
//  * components and automatically register them with their "basename".
//  *
//  * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
//  */
//
// // const files = require.context('./', true, /\.vue$/i)
// // files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
//
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//
// /**
//  * Next, we will create a fresh Vue application instance and attach it to
//  * the page. Then, you may begin adding components to this application
//  * or customize the JavaScript scaffolding to fit your unique needs.
//  */
//
// const app = new Vue({
//     el: '#app',
// });



AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
function updateScroll(){
    var element = document.getElementById("chatbot-body");
    element.scrollTop = element.scrollHeight;
}
function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function loadChatData(metadata){
    event.preventDefault();
    // console.log(metadata);
    let wrapper = $('.chat-mail');
    // console.log(isJson(metadata));
    if(isJson(metadata) == true) metadata = JSON.parse(metadata);
    $.ajax({
        url: initChatUrl,
        method: 'POST',
        data: metadata,
        success: data => {
            $('.chat-bubble').hide();
            wrapper.empty().append(data);
            updateScroll();
        }
    });
}
$(".chat-mail").on('click', '.cat-load', function (event) {
    event.preventDefault();
    const cat = $(this);
    console.log(cat.data('metadata'));
    loadChatData(cat.data('metadata'));
})

// $(document).ready(function () {
    //Toggle fullscreen
    $(".chat-bot-icon, .chat-bot-icon-mobile").click(function (e) {
        if($(this).hasClass('.chat-bot-icon')) {
            $(this).children('img').toggleClass('hide');
            $(this).children('svg').toggleClass('animate');
        }
        $('.chat-screen').toggleClass('show-chat');
        $('.chat-mail').empty();
        $('.chat-bubble').show();
        loadChatData({type: 'QuestionCategory', _token: token});
    });
    $('.chat-mail button').click(function () {
        $('.chat-mail').addClass('hide');
        $('.chat-body').removeClass('hide');
        $('.chat-input').removeClass('hide');
        $('.chat-header-option').removeClass('hide');
    });
    $('.end-chat').click(function () {
        $('.chat-body').addClass('hide');
        $('.chat-input').addClass('hide');
        $('.chat-session-end').removeClass('hide');
        $('.chat-header-option').addClass('hide');
    });
// });
